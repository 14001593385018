// extracted by mini-css-extract-plugin
export var Fieldset = "EquipmentLoanForm-module--Fieldset--Yb-Ug";
export var withMargin = "EquipmentLoanForm-module--withMargin--3JGqL";
export var Legend = "EquipmentLoanForm-module--Legend--10ZjF";
export var Submit = "EquipmentLoanForm-module--Submit--11Lmj";
export var ShortMessage = "EquipmentLoanForm-module--ShortMessage--2nTNE";
export var TimesIcon = "EquipmentLoanForm-module--TimesIcon--2-EqW";
export var CheckIcon = "EquipmentLoanForm-module--CheckIcon--3qBjf";
export var Notice = "EquipmentLoanForm-module--Notice--2z5vV";
export var fieldRow = "EquipmentLoanForm-module--fieldRow--3jMgQ";
export var fieldCol = "EquipmentLoanForm-module--fieldCol--2UpsO";