import { DonationFormModule } from '../DonationForm'
import { InvestmentLogin } from '../InvestmentLogin'
import { LocalChangeEnrollmentForm } from '../LocalChangeEnrollment'
import { EquipmentLoanForm } from '../EquipmentLoanForm'

const AdapterModule = ({ moduleName }) => {
  switch (moduleName) {
    case 'Donation-Form':
      return <DonationFormModule />
    case 'Investment-Login':
      return <InvestmentLogin />
    case 'Local-Change-Enrollment':
      return <LocalChangeEnrollmentForm />
    case 'Equipment-Loan-Form':
      return <EquipmentLoanForm />
    default:
      return <div style={{ padding: '4rem 0' }}>Warning, required module not found</div>
  }
}

export { AdapterModule }
export default AdapterModule
