/* eslint-disable camelcase */

import { Fragment, useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import axios from 'axios'
import qs from 'qs'
import { Trans, useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form/dist/index.ie11'
import { Helmet } from 'react-helmet'
import InputMask from 'react-input-mask'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCheck } from '@fortawesome/pro-regular-svg-icons'
import find from 'lodash/find'
import formatError from '../../../utilities/formatError'
import { Layout } from '../../layouts/layout'
import { Container, Row, Col } from '../../grid'
import { PageMeta } from '../../page-meta'
import { Alert } from '../../alert'
import { Stack } from '../../stack'
import { Button } from '../../button'
import { TextField } from '../../textfield'
import { Select } from '../../select'
import { Eyebrow } from '../../typography/Eyebrow'
import { Caption } from '../../typography/Caption'
import { Link } from '../../typography/Link'
import { Heading } from '../../typography/Heading'
import * as styles from './EquipmentLoanForm.module.scss'

const EMAIL_REGEX =
  /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/
const PHONE_REGEX = /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/

const EquipmentLoanForm = ({ location, pageContext }) => {
  const [t] = useTranslation()

  const validationSchema = {
    firstName: {
      required: t('ContactForm.validationMessages.firstNameRequired', {
        defaultValue: 'First name is required',
      }),
      maxLength: 50,
    },
    lastName: {
      required: t('ContactForm.validationMessages.lastNameRequired', {
        defaultValue: 'Last name is required',
      }),
      maxLength: 50,
    },
    businessName: {
      required: t('ContactForm.validationMessages.BusinessNameRequired', {
        defaultValue: 'Business name is required',
      }),
      maxLength: 50,
    },
    email: {
      required: t('ContactForm.validationMessages.emailRequired', {
        defaultValue: 'Email is required',
      }),
      maxLength: 100,
      validate: value =>
        EMAIL_REGEX.test(value) ||
        t('ContactForm.validationMessages.emailValid', {
          defaultValue: 'A valid email address is required',
        }),
    },
    phoneNumber: {
      required: t('ContactForm.validationMessages.phoneNumberRequired', {
        defaultValue: 'Phone number is required',
      }),
      validate: value =>
        PHONE_REGEX.test(value) ||
        t('ContactForm.validationMessages.phoneNumberValid', {
          defaultValue:
            'Please enter your full phone number, including area code in the following format XXX-XXX-XXXX',
        }),
    },
    message: { required: true },
    methodOfContact: {
      required: t('ContactForm.validationMessages.methodofContactRequired', {
        defaultValue: 'Preferred method of contact is required',
      }),
    },
    memberStatus: {
      required: t('ContactForm.validationMessages.memberStatus', {
        defaultValue: 'Membership status is required',
      }),
    },
  }

  const memberOptions = [
    { id: 'Yes', name: 'Yes' },
    { id: 'No', name: 'No' },
  ]

  const preferredContact = [
    { id: 'No Preference', name: 'No Preference' },
    { id: 'Phone', name: 'Phone' },
    { id: 'Email', name: 'Email' },
  ]

  const { register, reset, errors, watch, handleSubmit } = useForm({
    mode: 'onBlur',
  })

  const [alert, showAlert] = useState(null)
  const dismissAlert = useCallback(() => showAlert(null), [])

  const [inProgress, setInProgress] = useState(false)
  const handleSubmitValid = async formData => {
    setInProgress(true)
    showAlert(false)

    try {
      const payload = {
        form_id: 19,
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        phone_number: formData.phoneNumber,
        businessName: formData.businessName,
        elevationsMember: formData.elevationsMember,
        methodOfContact: formData.methodOfContact,
        message: formData.message,
      }

      const response = await axios.post(`${process.env.CONTACT_FORM_API_URL}/submit`, payload)

      if (response.status !== 200 || Boolean(response.data.error)) {
        throw new Error(`Unexpected status code: ${response.status} ${response.statusText}`)
      }

      reset()

      setInProgress(false)
      showAlert({
        type: 'success',
        title: t('ContactForm.successAlertTitle', { defaultValue: 'Thanks for reaching out!' }),
        message: t('ContactForm.successAlertMessage', {
          defaultValue: 'We’ve received your message and will be in touch with you shortly.',
        }),
      })
    } catch (error) {
      setInProgress(false)
      showAlert({
        type: 'error',
        title: t('ContactForm.errorAlertTitle', { defaultValue: 'Error' }),
        message: t('ContactForm.errorAlertMessage', {
          defaultValue:
            'Uh oh, looks like we’re having some internal server trouble. Please come back later and try again. For immediate help call us at 800-429-7626',
        }),
      })
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <Container className={styles.Content}>
      {alert && (
        <Row>
          <Col xs={12}>
            <Alert variant={alert.type} title={alert.title} onDismiss={dismissAlert} role="alert">
              {alert.message}
            </Alert>
          </Col>
        </Row>
      )}

      <Row>
        <div className={styles.RightBlock}>
          <div className={styles.Card}>
            <form
              action="/"
              method="POST"
              noValidate
              autoComplete="on"
              onSubmit={handleSubmit(handleSubmitValid)}
            >
              <Col md={12} lg={12}>
                <fieldset className={classNames(styles.Fieldset, styles.withMargin)}>
                  <legend className={styles.Legend}>
                    {t('ContactForm.yourInformationSectionTitle', {
                      defaultValue: 'Your information',
                    })}
                  </legend>
                  <Row className={styles.fieldRow}>
                    <Col sm={12} md={6} lg={3} className={styles.fieldCol}>
                      <TextField
                        label={`${t('ContactForm.businessNameFieldLabel', {
                          defaultValue: 'Business name',
                        })} (${t('ContactForm.required', { defaultValue: 'required' })})`}
                        id="businessName"
                        name="businessName"
                        ref={register(validationSchema.businessName)}
                        error={formatError(errors.businessName, validationSchema.businessName, t)}
                        autoFocus
                      />
                    </Col>
                    <Col sm={12} md={6} lg={3} className={styles.fieldCol}>
                      <TextField
                        label={`${t('ContactForm.firstNameFieldLabel', {
                          defaultValue: 'First name',
                        })} (${t('ContactForm.required', { defaultValue: 'required' })})`}
                        id="firstName"
                        name="firstName"
                        autoComplete="given-name"
                        ref={register(validationSchema.firstName)}
                        error={formatError(errors.firstName, validationSchema.firstName, t)}
                        autoFocus
                      />
                    </Col>
                    <Col sm={12} md={6} lg={3} className={styles.fieldCol}>
                      <TextField
                        label={`${t('ContactForm.lastNameFieldLabel', {
                          defaultValue: 'Last name',
                        })} (${t('ContactForm.required', { defaultValue: 'required' })})`}
                        id="lastName"
                        name="lastName"
                        autoComplete="family-name"
                        ref={register(validationSchema.lastName)}
                        error={formatError(errors.lastName, validationSchema.lastName, t)}
                      />
                    </Col>
                    <Col sm={12} md={6} lg={3} className={styles.fieldCol}>
                      <TextField
                        type="email"
                        label={`${t('ContactForm.emailFieldLabel', {
                          defaultValue: 'Email',
                        })} (${t('ContactForm.required', { defaultValue: 'required' })})`}
                        id="email"
                        name="email"
                        autoComplete="email"
                        ref={register(validationSchema.email)}
                        error={formatError(errors.email, validationSchema.email, t)}
                      />
                    </Col>
                  </Row>
                  <Row className={styles.fieldRow}>
                    <Col sm={12} md={6} lg={4} className={styles.fieldCol}>
                      <InputMask maskPlaceholder={null} mask="999-999-9999">
                        <TextField
                          type="tel"
                          label={`${t('ContactForm.phoneNumberFieldLabel', {
                            defaultValue: 'Phone number',
                          })} (${t('ContactForm.required', { defaultValue: 'required' })})`}
                          id="phoneNumber"
                          name="phoneNumber"
                          autoComplete="tel"
                          ref={register(validationSchema.phoneNumber)}
                          error={formatError(errors.phoneNumber, validationSchema.phoneNumber, t)}
                        />
                      </InputMask>
                    </Col>
                    <Col sm={12} md={6} lg={4} className={styles.fieldCol}>
                      <Select
                        label={`${t('ContactForm.isMemberField', {
                          defaultValue: 'Are you an Elevations member?',
                        })} (${t('ContactForm.required', { defaultValue: 'required' })})`}
                        id="elevationsMember"
                        name="elevationsMember"
                        ref={register(validationSchema.memberStatus)}
                        error={formatError(errors.memberStatus, validationSchema.memberStatus, t)}
                      >
                        <Select.Option value="">- - - - - - - -</Select.Option>
                        {memberOptions.map(option => (
                          <Select.Option key={`${option.id}`} value={`${option.name}`}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                    <Col sm={12} md={6} lg={4} className={styles.fieldCol}>
                      <Select
                        label={`${t('ContactForm.methodOfContact', {
                          defaultValue: 'Preferred method of contact',
                        })} (${t('ContactForm.required', { defaultValue: 'required' })})`}
                        id="methodOfContact"
                        name="methodOfContact"
                        ref={register(validationSchema.methodOfContact)}
                        error={formatError(
                          errors.methodOfContact,
                          validationSchema.methodOfContact,
                          t
                        )}
                      >
                        <Select.Option value="">- - - - - - - -</Select.Option>
                        {preferredContact.map(option => (
                          <Select.Option key={`${option.id}`} value={`${option.name}`}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={12} lg={12} className={styles.fieldCol}>
                      <TextField
                        label={`${t('ContactForm.additionalInfo', {
                          defaultValue: 'Additional Info',
                        })} (${t('ContactForm.required', {
                          defaultValue: 'required',
                        })})`}
                        multiline
                        id="message"
                        name="message"
                        ref={register(validationSchema.message)}
                        error={formatError(errors.message, validationSchema.message, t)}
                      />
                    </Col>
                  </Row>
                </fieldset>
              </Col>
              <Stack alignment="center" className={styles.Submit}>
                <Button
                  type="submit"
                  disabled={inProgress}
                  alt="Send contact form message"
                  loading={inProgress}
                >
                  {t('ContactForm.submitButtonSubmit', { defaultValue: 'Submit' })}
                </Button>

                {alert && alert.type === 'error' && (
                  <span className={styles.ShortMessage}>
                    <FontAwesomeIcon icon={faTimes} className={styles.TimesIcon} />
                    {t('ContactForm.successMessage', {
                      defaultValue: 'Your message was not sent',
                    })}
                  </span>
                )}

                {alert && alert.type === 'success' && (
                  <span className={styles.ShortMessage}>
                    <FontAwesomeIcon icon={faCheck} className={styles.CheckIcon} />
                    {t('ContactForm.errorMessage', {
                      defaultValue: 'Your message was sent successfully',
                    })}
                  </span>
                )}
              </Stack>

              <Caption className={styles.Notice}>
                {t('ContactForm.dataSecurityNotice', {
                  defaultValue:
                    'Data sent from this form is transferred to our internal staff via email.	Email is not considered secure and there is a possibility that someone can intercept your personal information. Please do not include personal	information such as account number, social security number, or other financial data in this form.',
                })}
              </Caption>
            </form>
          </div>
        </div>
      </Row>
    </Container>
  )
}

export { EquipmentLoanForm }
export default EquipmentLoanForm
